@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.history {
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 60vh;

  .table {
    height: 100%;
    background-color: #000;
    border: 1px solid #dcb432;
    overflow: auto;
  }

  table {
    width: 100%;
    border-spacing: 2px;

    th {
      padding: 10px 5px;
      background-color: #dcb432;
      color: #000;
    }

    td {
      padding: 10px;
      background-color: $white;
    }
    @media screen and (max-width: $breakpoint-mobile-portrait-lg){
      td{
        padding: 6px;
        font-size: 13px;
      }
    }
    @media screen and (max-width: $breakpoint-mobile-portrait-xs){
      td{
        padding: 5px;
        font-size: 11px;
      }
    }
  }

  .historyTable {
    text-align: center;
  }

  .btn {
    display: block;
    margin: 15px auto 0;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;

  &.invisible {
    display: none;
  }

  &__btn {
    height: 23px;
    margin: 0 4px;
    padding: 0 5px;
    display: inline-flex;
    line-height: 18px;
    border-radius: 2px;
    background-color: #fff;
    transition: 0.3s ease;
    font-size: 20px;

    &:disabled {
      background-color: rgba(#fff, 0.6);
    }

    
  }
  .hover{
    &:hover:not(:disabled) {
      opacity: 0.6;
    }
  }
  .active {
    &:active:not(:disabled) {
      opacity: 0.6;
    }
    &:focus:not(:disabled) {
      opacity: 0.6;
    }
  }
  .pages {
    display: flex;
    color: #fff;
    margin: 0 10px;

    .divider {
      opacity: 0.6;
      margin: 0 4px;
    }

    .current {
      color: #dcb432;
    }
  }
}
.spinner {
  position: relative;
  margin: auto;
  
  &:after {
    content: ' ';
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    justify-content: center;
    border: 6px solid #f2c94c;
    border-color: #f2c94c transparent #f2c94c transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
