@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.bet-settings {
  width: 300px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bet-settings::-webkit-scrollbar {
  display: none;
}

.input {
  display: block;
  width: 200px;
  margin: 10px auto;
}

.range-slider {
  width: 80%;
  margin: 0 auto;
}

.fast-bet {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 50%;
    padding: 15px 10px 0;
  }

  .btn {
    width: 100%;
  }
}

.btn {
  display: inline-flex;
  margin: 2px;
}